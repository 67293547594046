import { TIconProps } from './TIconProps'
export default function AccountCircle({ className = '' }: TIconProps) {
    return (
        <svg
            width="30"
            height="16"
            viewBox="0 0 30 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 8.9375C17.0375 8.9375 18.8375 9.425 20.3 10.0625C21.65 10.6625 22.5 12.0125 22.5 13.475V15.5H7.5V13.4875C7.5 12.0125 8.35 10.6625 9.7 10.075C11.1625 9.425 12.9625 8.9375 15 8.9375ZM5 9.25C6.375 9.25 7.5 8.125 7.5 6.75C7.5 5.375 6.375 4.25 5 4.25C3.625 4.25 2.5 5.375 2.5 6.75C2.5 8.125 3.625 9.25 5 9.25ZM6.4125 10.625C5.95 10.55 5.4875 10.5 5 10.5C3.7625 10.5 2.5875 10.7625 1.525 11.225C0.6 11.625 0 12.525 0 13.5375V15.5H5.625V13.4875C5.625 12.45 5.9125 11.475 6.4125 10.625ZM25 9.25C26.375 9.25 27.5 8.125 27.5 6.75C27.5 5.375 26.375 4.25 25 4.25C23.625 4.25 22.5 5.375 22.5 6.75C22.5 8.125 23.625 9.25 25 9.25ZM30 13.5375C30 12.525 29.4 11.625 28.475 11.225C27.4125 10.7625 26.2375 10.5 25 10.5C24.5125 10.5 24.05 10.55 23.5875 10.625C24.0875 11.475 24.375 12.45 24.375 13.4875V15.5H30V13.5375ZM15 0.5C17.075 0.5 18.75 2.175 18.75 4.25C18.75 6.325 17.075 8 15 8C12.925 8 11.25 6.325 11.25 4.25C11.25 2.175 12.925 0.5 15 0.5Z"
                fill="#9E9E9E"
            />
        </svg>
    )
}

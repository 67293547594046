import { TIconProps } from './TIconProps'
export default function AccountCircle({ className = '' }: TIconProps) {
    return (
        <svg
            width="34"
            height="28"
            viewBox="0 0 34 28"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 0.666626H5.33331C2.56665 0.666626 0.333313 2.89996 0.333313 5.66663V19C0.333313 21.7666 2.56665 24 5.33331 24L3.66665 25.6666V27.3333H5.33331L8.66665 23.95L12 24V15.6666H3.66665V3.96663L18.6666 3.99996V7.33329H22V5.66663C22 2.89996 19.7666 0.666626 17 0.666626ZM5.33331 17.3333C6.24998 17.3333 6.99998 18.0833 6.99998 19C6.99998 19.9166 6.24998 20.6666 5.33331 20.6666C4.41665 20.6666 3.66665 19.9166 3.66665 19C3.66665 18.0833 4.41665 17.3333 5.33331 17.3333ZM31.2833 10.1C31.05 9.43329 30.4166 8.99996 29.6666 8.99996H17.6833C16.9166 8.99996 16.3 9.43329 16.05 10.1L13.6666 16.95L13.6833 26.1333C13.6833 26.7666 14.2 27.3333 14.8333 27.3333H15.8666C16.5 27.3333 17 26.7 17 26.0666V24H30.3333V26.0666C30.3333 26.7 30.85 27.3333 31.4833 27.3333H32.5C33.1333 27.3333 33.65 26.7666 33.65 26.1333L33.6666 23.85V16.95L31.2833 10.1ZM17.6833 10.6666H29.6666L31.3833 15.6666H15.9666L17.6833 10.6666ZM17 20.6666C16.0833 20.6666 15.3333 19.9166 15.3333 19C15.3333 18.0833 16.0833 17.3333 17 17.3333C17.9166 17.3333 18.6666 18.0833 18.6666 19C18.6666 19.9166 17.9166 20.6666 17 20.6666ZM30.3333 20.6666C29.4166 20.6666 28.6666 19.9166 28.6666 19C28.6666 18.0833 29.4166 17.3333 30.3333 17.3333C31.25 17.3333 32 18.0833 32 19C32 19.9166 31.25 20.6666 30.3333 20.6666Z"
                // fill="#9E9E9E"
            />
        </svg>
    )
}

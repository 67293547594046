// theme constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320

// Selects Constants
export const COMPANY_TYPES = [
    {
        value: 'Pública',
        label: 'Pública',
    },
    {
        value: 'Privada',
        label: 'Privada',
    },
    {
        value: 'Mixta',
        label: 'Mixta',
    },
    {
        value: 'Asociación cooperativa',
        label: 'Asociación cooperativa',
    },
    {
        value: 'Compañia indivual',
        label: 'Compañia indivual',
    },
]

export const RIF_OPTIONS = [
    {
        value: 'J',
        label: 'J',
    },
    {
        value: 'G',
        label: 'G',
    },
    {
        value: 'V',
        label: 'V',
    },
    {
        value: 'E',
        label: 'E',
    },
]

export const STATES = [
    {
        id: '6179ad5090f57b09f772c906',
        state_code: '01',
        alpha_code: 'DF',
        name: 'Distrito Capital',
        abbreviation: null,
        description: 'Estado Anzoategui',
    },
    {
        id: '6179ad5090f57b09f772c907',
        state_code: '02',
        alpha_code: 'AM',
        name: 'Amazonas',
        abbreviation: null,
        description: 'Estado Amazonas',
    },
    {
        id: '6179ad5090f57b09f772c908',
        state_code: '03',
        alpha_code: 'AN',
        name: 'Anzoategui',
        abbreviation: null,
        description: 'Estado Anzoategui',
    },
    {
        id: '6179ad5090f57b09f772c909',
        state_code: '04',
        alpha_code: 'AP',
        name: 'Apure',
        abbreviation: null,
        description: 'Estado Apure',
    },
    {
        id: '6179ad5090f57b09f772c90a',
        state_code: '05',
        alpha_code: 'AR',
        name: 'Aragua',
        abbreviation: null,
        description: 'Estado Aragua',
    },
    {
        id: '6179ad5090f57b09f772c90b',
        state_code: '06',
        alpha_code: 'BA',
        name: 'Barina',
        abbreviation: null,
        description: 'Estado Barinas',
    },
    {
        id: '6179ad5090f57b09f772c90c',
        state_code: '07',
        alpha_code: 'BO',
        name: 'Bolivar',
        abbreviation: null,
        description: 'Estado Bolivar',
    },
    {
        id: '6179ad5090f57b09f772c90d',
        state_code: '08',
        alpha_code: 'CA',
        name: 'Carabobo',
        abbreviation: null,
        description: 'Estado Carabobo',
    },
    {
        id: '6179ad5090f57b09f772c90e',
        state_code: '09',
        alpha_code: 'CO',
        name: 'Cojedes',
        abbreviation: null,
        description: 'Estado Cojedes',
    },
    {
        id: '6179ad5090f57b09f772c90f',
        state_code: '10',
        alpha_code: 'DA',
        name: 'Delta Amacuro',
        abbreviation: null,
        description: 'Estado Delta Amacuro',
    },
    {
        id: '6179ad5090f57b09f772c910',
        state_code: '11',
        alpha_code: 'FA',
        name: 'Falcon',
        abbreviation: null,
        description: 'Estado Falcon',
    },
    {
        id: '6179ad5090f57b09f772c911',
        state_code: '12',
        alpha_code: 'GU',
        name: 'Guarico',
        abbreviation: null,
        description: 'Estado Guarico',
    },
    {
        id: '6179ad5090f57b09f772c912',
        state_code: '13',
        alpha_code: 'LA',
        name: 'Distrplito Capdsital',
        abbreviation: null,
        description: 'Estado Lara',
    },
    {
        id: '6179ad5090f57b09f772c913',
        state_code: '14',
        alpha_code: 'ME',
        name: 'Merida',
        abbreviation: null,
        description: 'Estado Merida',
    },
    {
        id: '6179ad5090f57b09f772c914',
        state_code: '15',
        alpha_code: 'MI',
        name: 'Miranda',
        abbreviation: null,
        description: 'Estado Miranda',
    },
    {
        id: '6179ad5090f57b09f772c915',
        state_code: '16',
        alpha_code: 'MO',
        name: 'Monagas',
        abbreviation: null,
        description: 'Estado Monagas',
    },
    {
        id: '6179ad5090f57b09f772c916',
        state_code: '17',
        alpha_code: 'NE',
        name: 'Nueva Esparta',
        abbreviation: null,
        description: 'Estado Nueva Esparta',
    },
    {
        id: '6179ad5090f57b09f772c917',
        state_code: '18',
        alpha_code: 'PO',
        name: 'Portuguesa',
        abbreviation: null,
        description: 'Estado Portuguesa',
    },
    {
        id: '6179ad5090f57b09f772c918',
        state_code: '19',
        alpha_code: 'SU',
        name: 'Sucre',
        abbreviation: null,
        description: 'Estado Sucre',
    },
    {
        id: '6179ad5190f57b09f772c919',
        state_code: '20',
        alpha_code: 'TA',
        name: 'Tachira',
        abbreviation: null,
        description: 'Estado Tachira',
    },
    {
        id: '6179ad5190f57b09f772c91a',
        state_code: '21',
        alpha_code: 'TR',
        name: 'Trujillo',
        abbreviation: null,
        description: 'Estado Trujillo',
    },
    {
        id: '6179ad5190f57b09f772c91b',
        state_code: '24',
        alpha_code: 'VA',
        name: 'Vargas',
        abbreviation: null,
        description: 'Estado Vargas',
    },
    {
        id: '6179ad5190f57b09f772c91c',
        state_code: '22',
        alpha_code: 'YA',
        name: 'Yaracuy',
        abbreviation: null,
        description: 'Estado Yaracuy',
    },
    {
        id: '6179ad5190f57b09f772c91d',
        state_code: '23',
        alpha_code: 'ZU',
        name: 'Zulia',
        abbreviation: null,
        description: 'Estado Zulia',
    },
    {
        id: '61815b20e554bf6c947be42c',
        state_code: '47',
        alpha_code: 'Dp',
        name: 'Distrplito Capital',
        abbreviation: 'DT',
        description: 'pgui',
    },
]

export const CITIES = [
    {
        id: '6179abe3375505d132e8b536',
        city_code: '1501',
        alpha_code: 'VE.MI.AC',
        name: 'Acevedo',
        abbreviation: 'MI.AC',
        description: 'Acevedo_Miranda',
    },
    {
        id: '6179abe3375505d132e8b537',
        city_code: '0401',
        alpha_code: 'VE.AP.AC',
        name: 'Achaguas',
        abbreviation: 'AP.AC',
        description: 'Achaguas',
    },
    {
        id: '6179abe3375505d132e8b538',
        city_code: '1601',
        alpha_code: 'VE.MO.AC',
        name: 'Acosta',
        abbreviation: 'MO.AC',
        description: 'Acosta_Monagas',
    },
    {
        id: '6179abe3375505d132e8b539',
        city_code: '1101',
        alpha_code: 'VE.FA.AC',
        name: 'Acosta',
        abbreviation: 'FA.AC',
        description: 'Acosta_Falcon',
    },
    {
        id: '6179abe3375505d132e8b53a',
        city_code: '1801',
        alpha_code: 'VE.PO.AB',
        name: 'Agua Blanca',
        abbreviation: 'PO.AB',
        description: 'Agua_Blanca_Portuguesa',
    },
    {
        id: '6179abe3375505d132e8b53b',
        city_code: '1602',
        alpha_code: 'VE.MO.AG',
        name: 'Aguasay',
        abbreviation: 'MO.AG',
        description: 'Aguasay',
    },
    {
        id: '6179abe3375505d132e8b53c',
        city_code: '1401',
        alpha_code: 'VE.ME.AA',
        name: 'Alberto Adriani',
        abbreviation: 'ME.AA',
        description: 'Alberto_Adriani_Merida',
    },
]

export const ACCOUNT_TYPES = [
    {
        value: 'ahorro',
        label: 'Ahorro',
    },
    { value: 'corriente', label: 'Corriente' },
]

export const BANKS = [
    {
        id: '618035b1e554bf6c947be3c7',
        bank_code: '0116',
        bank_name: 'BANCO OCCIDENTAL DE DESCUENTO CA',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3c8',
        bank_code: '0137',
        bank_name: 'BANCO SOFITASA',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3c9',
        bank_code: '0138',
        bank_name: 'BANCO PLAZA',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3ca',
        bank_code: '0157',
        bank_name: 'EAP DEL SUR',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3cb',
        bank_code: '0151',
        bank_name: 'FONDO COMUN BANCO UNIVERSAL',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3cc',
        bank_code: '0191',
        bank_name: 'BANCO NACIONAL DE CREDITO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3cd',
        bank_code: '0105',
        bank_name: 'BANCO MERCANTIL',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3ce',
        bank_code: '0104',
        bank_name: 'BANCO VENEZOLANO DE CREDITO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3cf',
        bank_code: '0108',
        bank_name: 'BANCO PROVINCIAL',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d0',
        bank_code: '0115',
        bank_name: 'BANCO EXTERIOR',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d1',
        bank_code: '0134',
        bank_name: 'BANESCO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d2',
        bank_code: '0114',
        bank_name: 'BANCO DEL CARIBE',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d3',
        bank_code: '0003',
        bank_name: 'BANCO INDUSTRIAL DE VENEZUELA',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d4',
        bank_code: '0190',
        bank_name: 'CITIBANK',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d5',
        bank_code: '0128',
        bank_name: 'BANCO CARONI',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d6',
        bank_code: '0175',
        bank_name: 'BANCO BICENTENARIO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d7',
        bank_code: '0156',
        bank_name: '100 PORCIENTO BANCO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d8',
        bank_code: '0163',
        bank_name: 'BANCO DEL TESORO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3d9',
        bank_code: '0166',
        bank_name: 'BANCO AGRICOLA',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3da',
        bank_code: '0174',
        bank_name: 'BANPLUS  BANCO COMERCIAL',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3db',
        bank_code: '0169',
        bank_name: 'MIBANCO BANCO DE DESARROLLO',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3dc',
        bank_code: '0102',
        bank_name: 'BANCO DE VENEZUELA',
        swift_code: 'VZLAVECA',
    },
    {
        id: '618035b1e554bf6c947be3dd',
        bank_code: '0168',
        bank_name: 'BANCRECER',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3de',
        bank_code: '0172',
        bank_name: 'BANCAMIGA BANCO MICROFINANCIERO  C A',
        swift_code: null,
    },
    {
        id: '618035b1e554bf6c947be3df',
        bank_code: '0177',
        bank_name: 'BANCO DE LAS FUERZAS ARMADAS BANFANB',
        swift_code: null,
    },
]

export const SEX = [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Femenino' },
]

export const DEPARTMENTS = [
    {
        value: 1005,
        label: 'Administrativo',
    },
]

export const NUMBER_CODE = [
    {
        label: '0414',
        value: '0414',
    },
    {
        label: '0424',
        value: '0424',
    },
    {
        label: '0412',
        value: '0412',
    },
    {
        label: '0416',
        value: '0416',
    },
    {
        label: '0426',
        value: '0426',
    },
]

export const ROLES = [
    {
        label: 'Administrador',
        value: 'Administrador',
    },
    {
        label: 'Contador',
        value: 'Contador',
    },
]

export const TRANSPORT_TYPE = [
    {
        label: 'Ferroviarios',
        value: 'Ferroviarios',
    },
    {
        label: 'Metro',
        value: 'Metro',
    },
    {
        label: 'Autobuses',
        value: 'Autobuses',
    },
    {
        label: 'Taxi',
        value: 'Taxi',
    },
    {
        label: 'Moto taxi',
        value: 'Moto taxi',
    },
    {
        label: 'Ferries',
        value: 'Ferries',
    },
    {
        label: 'Botes',
        value: 'Botes',
    },
]
export const TRANSPORT_TYPES = [
    {
        label: 'Ferroviario',
        value: 'ferroviarios',
    },
    {
        label: 'Metro',
        value: 'metro',
    },
    {
        label: 'Autobus',
        value: 'autobuses',
    },
    {
        label: 'Taxi',
        value: 'taxi',
    },
    {
        label: 'Moto taxi',
        value: 'mototaxi',
    },
    {
        label: 'Ferry',
        value: 'ferries',
    },
    {
        label: 'Bote',
        value: 'botes',
    },
]

export const FUEL_TYPES = [
    {
        label: 'Gasolina',
        value: 'gas',
    },
    {
        label: 'Diesel',
        value: 'diesel',
    },
    {
        label: 'G.L.P',
        value: 'glp',
    },
]

export const documentTypeJ = [
    {
        value: 'J',
        label: 'J',
    },
    {
        value: 'G',
        label: 'G',
    },
]

export const documentType = [
    {
        value: 'J',
        label: 'J',
    },
    {
        value: 'G',
        label: 'G',
    },
    {
        value: 'V',
        label: 'V',
    },
    {
        value: 'E',
        label: 'E',
    },
]

export const documentTypeN = [
    {
        value: 'V',
        label: 'V',
    },
    {
        value: 'E',
        label: 'E',
    },
]
export const documentTypeJLegal = [
    {
        value: 'V',
        label: 'V',
    },
    {
        value: 'E',
        label: 'E',
    },
    {
        value: 'J',
        label: 'J',
    },
    {
        value: 'G',
        label: 'G',
    },
]

export const Media = [
    {
        value: 'BARCODE',
        label: 'Código de barra',
    },
    {
        value: 'QR',
        label: 'Qr',
    },
    {
        value: 'NFC',
        label: 'NFC',
    },
    {
        value: 'TAG_REF',
        label: 'Referencia',
    },
]

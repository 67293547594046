import { TIconProps } from './TIconProps'
export default function AccountCircle({ className = '' }: TIconProps) {
    return (
        <svg
            id="a9c77ae4-a239-4ae6-a514-7005f0c2da60"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                d="M22,11.28a.77.77,0,0,0-.72-.5H15.84c1.62-.94,3.24-1.87,4.87-2.76.7-.39.3-.69.12-1.07s-.37-.6-.9-.29c-2.66,1.56-5.37,3.12-8.06,4.67a2.35,2.35,0,0,1-1.6.45c-.58-.1-.66.19-.65.69v4.31c0,.22.16.61-.25.59s-.22-.39-.22-.62v-11c0-.52-.12-.7-.67-.69H2.7c-.6,0-.72.16-.71.73v11c0,.41,0,.74-.58.7s-.33.55-.42.9.08.55.5.55h10.1c.5,0,.55-.23.51-.6s.32-1-.47-.95c-.45,0-.5-.19-.49-.56V14.19a.94.94,0,0,1,.12-.57.9.9,0,0,1,.43-.39l3.45-2-.06.11-1.05,3v4a.52.52,0,0,0,.5.5H15a.48.48,0,0,0,.36-.15.47.47,0,0,0,.15-.35v-.56h6v.51a.52.52,0,0,0,.5.5h.5a.48.48,0,0,0,.36-.15.47.47,0,0,0,.15-.35v-4ZM8.43,10.44c0,.36-.1.5-.48.5H3.27c-.35,0-.51-.07-.5-.46V6.17c0-.4.18-.46.51-.45h4.6c.39,0,.56.08.56.5C8.4,7.62,8.41,9,8.43,10.44Zm7.9,5.64a.77.77,0,0,1-.54.22.79.79,0,0,1-.42-.12.84.84,0,0,1-.28-.34.82.82,0,0,1,0-.44.81.81,0,0,1,.2-.39.86.86,0,0,1,.39-.2.66.66,0,0,1,.44,0,.75.75,0,0,1,.47.7A.7.7,0,0,1,16.33,16.08ZM22,15.84a.78.78,0,0,1-.16.24.85.85,0,0,1-.25.17.93.93,0,0,1-.29.05h0a.77.77,0,0,1-.7-.46.82.82,0,0,1,0-.44.81.81,0,0,1,.2-.39.79.79,0,0,1,.83-.16.75.75,0,0,1,.47.7A.63.63,0,0,1,22,15.84Zm-7-2,.76-2.22H21.3l.78,2.2Z"
                fill="#9e9e9e"
            />
        </svg>
    )
}

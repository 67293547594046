import { TIconProps } from './TIconProps'
export default function AccountCircle({ className = '' }: TIconProps) {
    return (
        <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.88 13.3132L15.2284 15.9648L22.7237 23.4601L25.3753 20.8085L17.88 13.3132Z"
                fill="#9E9E9E"
            />
            <path
                d="M19.875 9.5C22.2875 9.5 24.25 7.5375 24.25 5.125C24.25 4.4 24.05 3.725 23.7375 3.125L20.3625 6.5L18.5 4.6375L21.875 1.2625C21.275 0.95 20.6 0.75 19.875 0.75C17.4625 0.75 15.5 2.7125 15.5 5.125C15.5 5.6375 15.6 6.125 15.7625 6.575L13.45 8.8875L11.225 6.6625L12.1125 5.775L10.35 4.0125L13 1.3625C11.5375 -0.1 9.1625 -0.1 7.7 1.3625L3.275 5.7875L5.0375 7.55H1.5125L0.625 8.4375L5.05 12.8625L5.9375 11.975V8.4375L7.7 10.2L8.5875 9.3125L10.8125 11.5375L1.55 20.8L4.2 23.45L18.425 9.2375C18.875 9.4 19.3625 9.5 19.875 9.5Z"
                fill="#9E9E9E"
            />
        </svg>
    )
}

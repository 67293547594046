import { TIconProps } from './TIconProps'
export default function AccountCircle({ className = '' }: TIconProps) {
    return (
        <svg
            width="44"
            height="40"
            viewBox="0 0 44 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_369_6366)">
                <path d="M20.58 29.94C19.77 30.03 19.67 29.61 19.69 28.94C19.69 27.31 19.75 25.68 19.69 24.06C19.6456 23.7008 19.7191 23.3369 19.8994 23.0231C20.0798 22.7092 20.3572 22.4625 20.69 22.32C26.13 19.22 31.53 16 37 13C38.26 12.29 37.54 11.76 37.21 11.06C36.88 10.36 36.54 9.97004 35.58 10.54C30.76 13.37 25.86 16.13 21 19C20.165 19.5979 19.1425 19.8748 18.12 19.78C17.07 19.6 16.92 20.12 16.94 21.03C16.94 23.63 16.94 26.24 16.94 28.84C16.94 29.24 17.23 29.94 16.49 29.91C15.75 29.88 16.08 29.2 16.08 28.79C16.08 25.44 16.08 22.1 16.08 18.79C16.08 15.48 16.08 12.09 16.08 8.79004C16.08 7.85004 15.88 7.52004 14.87 7.54004C11.38 7.60004 7.86997 7.61004 4.40997 7.54004C3.32997 7.54004 3.10997 7.83004 3.11997 8.86004C3.16997 15.5 3.11997 22.16 3.11997 28.86C3.11997 29.6 3.17997 30.2 2.06997 30.13C0.709969 30.04 1.46997 31.13 1.31997 31.75C1.16997 32.37 1.45997 32.75 2.21997 32.75C8.30997 32.75 14.41 32.75 20.5 32.75C21.41 32.75 21.5 32.34 21.42 31.66C21.34 30.98 22 29.78 20.58 29.94ZM14.76 17.39C14.76 18.03 14.58 18.29 13.9 18.29C11.07 18.29 8.22997 18.29 5.39997 18.29C4.76997 18.29 4.48997 18.16 4.49997 17.45C4.53997 14.85 4.53997 12.25 4.49997 9.65004C4.49997 8.92004 4.81997 8.82004 5.41997 8.84004C6.80997 8.84004 8.20997 8.84004 9.59997 8.84004C10.99 8.84004 12.39 8.84004 13.78 8.84004C14.47 8.84004 14.78 8.98004 14.78 9.75004C14.72 12.28 14.73 14.84 14.76 17.39Z" />
                <path d="M41.21 18.9C41.1134 18.6357 40.9378 18.4075 40.707 18.2464C40.4762 18.0854 40.2014 17.9994 39.92 18H29.92C29.6365 18.0023 29.3606 18.0918 29.1298 18.2564C28.899 18.421 28.7245 18.6527 28.63 18.92L26.73 24.38V31.66C26.73 31.9014 26.8259 32.1328 26.9965 32.3035C27.1672 32.4741 27.3986 32.57 27.64 32.57H28.55C28.7913 32.57 29.0228 32.4741 29.1934 32.3035C29.3641 32.1328 29.46 31.9014 29.46 31.66V30.75H40.38V31.66C40.38 31.9014 40.4759 32.1328 40.6465 32.3035C40.8172 32.4741 41.0486 32.57 41.29 32.57H42.2C42.4413 32.57 42.6728 32.4741 42.8434 32.3035C43.0141 32.1328 43.11 31.9014 43.11 31.66V24.36L41.21 18.9ZM29.91 28C29.6386 28.002 29.3728 27.9233 29.1461 27.774C28.9195 27.6247 28.7424 27.4114 28.6372 27.1612C28.5319 26.9111 28.5034 26.6353 28.5551 26.3689C28.6068 26.1025 28.7365 25.8575 28.9277 25.6649C29.1189 25.4723 29.363 25.3408 29.629 25.2871C29.895 25.2334 30.171 25.26 30.4219 25.3634C30.6728 25.4668 30.8874 25.6423 31.0384 25.8678C31.1894 26.0934 31.27 26.3586 31.27 26.63C31.27 26.9916 31.127 27.3386 30.8723 27.5952C30.6175 27.8519 30.2716 27.9974 29.91 28ZM39.91 28C39.639 28 39.3741 27.9197 39.1488 27.7691C38.9236 27.6186 38.748 27.4046 38.6443 27.1543C38.5406 26.9039 38.5134 26.6285 38.5663 26.3627C38.6192 26.097 38.7496 25.8529 38.9412 25.6613C39.1328 25.4697 39.377 25.3392 39.6427 25.2863C39.9085 25.2335 40.1839 25.2606 40.4343 25.3643C40.6846 25.468 40.8986 25.6436 41.0491 25.8689C41.1996 26.0942 41.28 26.359 41.28 26.63C41.2813 26.8094 41.2471 26.9874 41.1793 27.1535C41.1116 27.3197 41.0116 27.4708 40.8852 27.5981C40.7588 27.7255 40.6084 27.8266 40.4427 27.8955C40.2771 27.9645 40.0994 28 39.92 28H39.91ZM28.54 23.45L29.91 19.35H39.91L41.28 23.45H28.54Z" />
            </g>
            {/* <defs>
                <clipPath id="clip0_369_6366">
                    <rect width="43.76" height="40" fill="white" />
                </clipPath>
            </defs> */}
        </svg>
    )
}

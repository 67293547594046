import { TIconProps } from './TIconProps'
export default function AccountCircle({ className = '' }: TIconProps) {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24.8 24"
        >
            <path
                d="M8.3,10.6c-.3,0-.3-.1-.3-.4v-2a.6.6,0,0,1,.1-.4l.3-.3c2.2-1.3,4.3-2.5,6.5-3.8.5-.2.2-.4.1-.7s-.3-.4-.7-.2c-1.9,1.1-3.9,2.3-5.8,3.4a2,2,0,0,1-1.1.3c-.5-.1-.5.1-.5.5v3.1c0,.2.1.4-.2.4s-.2-.3-.2-.5V2c0-.4-.1-.5-.5-.5H1.8c-.4,0-.5.1-.5.5v8c0,.3,0,.5-.4.5-.6,0-.2.4-.3.7s.1.4.4.4H8.3c.4,0,.4-.2.4-.4S8.9,10.5,8.3,10.6ZM6,5.6c0,.3-.1.4-.3.4H2.3c-.3-.1-.4-.1-.4-.4V2.5c0-.3.1-.3.4-.3H5.7c.3,0,.4.1.4.4A28.12,28.12,0,0,0,6,5.6Z"
                fill="#9e9e9e"
            />
            <path
                d="M24.4,13.5c-.1-.3-.3-.4-.7-.2-1.9,1.1-3.9,2.3-5.8,3.4a2,2,0,0,1-1.1.3c-.4-.1-.5.1-.5.5v3.1c0,.2.1.4-.2.4s-.2-.3-.2-.4v-8c0-.4-.1-.5-.5-.5H11.2c-.4,0-.5.1-.5.5v8c0,.3,0,.5-.4.5-.6,0-.2.4-.3.7s.1.4.4.4h7.3c.4,0,.4-.2.4-.4s.2-.7-.4-.6c-.3,0-.4-.1-.4-.4v-2a.6.6,0,0,1,.1-.4l.3-.3c2.2-1.2,4.3-2.5,6.5-3.8C24.8,14,24.5,13.8,24.4,13.5Zm-9,2.6c0,.3-.1.4-.3.4H11.7c-.3,0-.4-.1-.4-.3V13.1c0-.3.1-.3.4-.3h3.4c.3,0,.4.1.4.4A25.32,25.32,0,0,0,15.4,16.1Z"
                fill="#9e9e9e"
            />
        </svg>
    )
}

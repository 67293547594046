import { TIconProps } from './TIconProps'

export default function LogoGobLight({ className = '' }: TIconProps) {
    return (
        <svg
            // id="Layer_1"
            // data-name="Layer 1"
            // xmlns="http://www.w3.org/2000/svg"
            //  xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 217.28 27.84"
            className={className}
        >
            <defs>
                <clipPath id="clipPath">
                    <path
                        d="M4.82,7.55A12.65,12.65,0,0,0,0,11.68V24.76H6.59a19.43,19.43,0,0,1,3.91-3,8,8,0,0,1,3.91-.91L12,6.06H11.8a16.29,16.29,0,0,0-7,1.49"
                        fill="none"
                    />
                </clipPath>
                <clipPath id="clipPath-2">
                    <path
                        d="M16.31,5.46A14.16,14.16,0,0,1,12.2,7.15l2.38,15.3a20.1,20.1,0,0,0,3.82-1A17.76,17.76,0,0,0,21.27,20V.66a13.22,13.22,0,0,1-5,4.8"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <path
                d="M24.7,21.87a2.47,2.47,0,0,0,.47,1.67,1.39,1.39,0,0,0,2.21,0A2.47,2.47,0,0,0,27.84,22a2.71,2.71,0,0,0-.47-1.75,1.41,1.41,0,0,0-2.23,0,2.64,2.64,0,0,0-.44,1.7m3.12,2.91V24a1.82,1.82,0,0,1-1.63.87,2.35,2.35,0,0,1-1.29-.38,2.55,2.55,0,0,1-.9-1.07,3.64,3.64,0,0,1-.32-1.59A4,4,0,0,1,24,20.29a2.26,2.26,0,0,1,.87-1.09,2.38,2.38,0,0,1,1.3-.38,1.92,1.92,0,0,1,.94.22,1.89,1.89,0,0,1,.67.58V16.73h1v8.05Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M30.54,21.3h3.25a1.91,1.91,0,0,0-.37-1.1,1.5,1.5,0,0,0-1.22-.57,1.58,1.58,0,0,0-1.15.45,1.82,1.82,0,0,0-.51,1.22m3.24,1.6,1,.12a2.43,2.43,0,0,1-.89,1.39,3,3,0,0,1-3.7-.29,3,3,0,0,1-.75-2.21,3.25,3.25,0,0,1,.76-2.28,2.56,2.56,0,0,1,2-.81,2.51,2.51,0,0,1,1.91.79,3.18,3.18,0,0,1,.74,2.24c0,.06,0,.15,0,.27H30.48A2.24,2.24,0,0,0,31,23.59a1.66,1.66,0,0,0,1.22.51,1.49,1.49,0,0,0,.92-.29,1.85,1.85,0,0,0,.61-.91"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M40.61,24.78l-3.12-8h1.15l2.09,5.85c.17.47.31.91.43,1.32.12-.44.27-.88.43-1.32l2.17-5.85h1.09L41.7,24.78Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M45.93,21.3h3.26a2.07,2.07,0,0,0-.38-1.1,1.5,1.5,0,0,0-1.22-.57,1.6,1.6,0,0,0-1.15.45,1.82,1.82,0,0,0-.51,1.22m3.24,1.6,1,.12a2.45,2.45,0,0,1-.9,1.39,2.61,2.61,0,0,1-1.67.5,2.66,2.66,0,0,1-2-.79,3,3,0,0,1-.74-2.21,3.25,3.25,0,0,1,.75-2.28,2.58,2.58,0,0,1,2-.81,2.51,2.51,0,0,1,1.91.79,3.18,3.18,0,0,1,.74,2.24c0,.06,0,.15,0,.27H45.87a2.29,2.29,0,0,0,.55,1.47,1.64,1.64,0,0,0,1.21.51,1.55,1.55,0,0,0,.94-.29,1.84,1.84,0,0,0,.6-.91"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M50.94,24.78V19h.89v.83a2.1,2.1,0,0,1,1.86-1,2.32,2.32,0,0,1,1,.19,1.54,1.54,0,0,1,.67.5,1.8,1.8,0,0,1,.3.72,5.34,5.34,0,0,1,.06,1v3.58h-1V21.24a2.58,2.58,0,0,0-.12-.91.85.85,0,0,0-.41-.47,1.26,1.26,0,0,0-.68-.19,1.59,1.59,0,0,0-1.09.41,2,2,0,0,0-.46,1.52v3.18Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M57.45,21.3H60.7a2,2,0,0,0-.37-1.1,1.54,1.54,0,0,0-1.23-.57,1.58,1.58,0,0,0-1.14.45,1.82,1.82,0,0,0-.51,1.22m3.24,1.6,1,.12a2.43,2.43,0,0,1-.89,1.39,2.63,2.63,0,0,1-1.67.5,2.68,2.68,0,0,1-2-.79,3.09,3.09,0,0,1-.74-2.21,3.21,3.21,0,0,1,.76-2.28,2.56,2.56,0,0,1,2-.81,2.48,2.48,0,0,1,1.91.79,3.18,3.18,0,0,1,.74,2.24v.27H57.39a2.28,2.28,0,0,0,.54,1.47,1.68,1.68,0,0,0,1.22.51,1.49,1.49,0,0,0,.93-.29,1.85,1.85,0,0,0,.61-.91"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M61.94,24.78V24l3.7-4.25-1.11,0H62.16V19h4.76v.65l-3.16,3.7-.61.68c.44,0,.86-.06,1.25-.06h2.69v.86Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M71.4,24.78v-.86a2.12,2.12,0,0,1-1.84,1,2.25,2.25,0,0,1-1-.2,1.69,1.69,0,0,1-.67-.49,2,2,0,0,1-.3-.74,5,5,0,0,1-.06-.92V19h1v3.23a5.87,5.87,0,0,0,.06,1.05,1,1,0,0,0,.39.61,1.23,1.23,0,0,0,.75.22,1.51,1.51,0,0,0,.83-.23,1.24,1.24,0,0,0,.56-.62,3.25,3.25,0,0,0,.16-1.14V19h1v5.83Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M74.09,21.3h3.25A2,2,0,0,0,77,20.2a1.53,1.53,0,0,0-1.22-.57,1.58,1.58,0,0,0-1.15.45,1.82,1.82,0,0,0-.51,1.22m3.24,1.6,1,.12a2.43,2.43,0,0,1-.89,1.39,3,3,0,0,1-3.7-.29A3,3,0,0,1,73,21.91a3.21,3.21,0,0,1,.76-2.28,2.58,2.58,0,0,1,2-.81,2.51,2.51,0,0,1,1.91.79,3.18,3.18,0,0,1,.73,2.24v.27H74a2.24,2.24,0,0,0,.55,1.47,1.64,1.64,0,0,0,1.21.51,1.52,1.52,0,0,0,.93-.29,1.85,1.85,0,0,0,.61-.91"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <rect
                x="79.08"
                y="16.73"
                width="0.99"
                height="8.05"
                fill="#6d6e70"
            />
            <path
                d="M84.82,21.85a7.2,7.2,0,0,1-1.61.38,3.29,3.29,0,0,0-.86.2.81.81,0,0,0-.39.31.89.89,0,0,0-.14.47.83.83,0,0,0,.3.66,1.27,1.27,0,0,0,.88.27,2.07,2.07,0,0,0,1-.25,1.57,1.57,0,0,0,.66-.69,2.48,2.48,0,0,0,.15-1Zm.09,2.21a4,4,0,0,1-1.06.66,3,3,0,0,1-1.09.19,2.15,2.15,0,0,1-1.48-.47,1.55,1.55,0,0,1-.51-1.2,1.58,1.58,0,0,1,.19-.78,1.75,1.75,0,0,1,.51-.57,2.41,2.41,0,0,1,.71-.32,8.12,8.12,0,0,1,.88-.15,8.62,8.62,0,0,0,1.76-.34c0-.13,0-.22,0-.26a1.13,1.13,0,0,0-.28-.85,1.68,1.68,0,0,0-1.13-.33,1.78,1.78,0,0,0-1,.24,1.51,1.51,0,0,0-.49.86l-1-.13a2.38,2.38,0,0,1,.44-1,1.8,1.8,0,0,1,.87-.59,3.87,3.87,0,0,1,1.33-.21,3.7,3.7,0,0,1,1.21.17,1.38,1.38,0,0,1,1,1.12,5.89,5.89,0,0,1,0,.91v1.32a14.71,14.71,0,0,0,.06,1.74,2.55,2.55,0,0,0,.25.7h-1a2.24,2.24,0,0,1-.19-.72"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M27.93,12.36v-.94h3.41v3a6.13,6.13,0,0,1-1.62.94,4.85,4.85,0,0,1-1.72.32,4.58,4.58,0,0,1-2.15-.51,3.35,3.35,0,0,1-1.46-1.47,4.59,4.59,0,0,1-.5-2.14,5,5,0,0,1,.49-2.19A3.28,3.28,0,0,1,25.8,7.83a4.39,4.39,0,0,1,2.12-.49,4.19,4.19,0,0,1,1.58.28,2.54,2.54,0,0,1,1.1.79,3.52,3.52,0,0,1,.62,1.32l-1,.26a2.86,2.86,0,0,0-.45-1A1.79,1.79,0,0,0,29,8.47a2.68,2.68,0,0,0-1.11-.22,3.19,3.19,0,0,0-1.26.23,2.29,2.29,0,0,0-.87.58,3,3,0,0,0-.5.79A4.14,4.14,0,0,0,25,11.47a4,4,0,0,0,.36,1.8,2.47,2.47,0,0,0,1.09,1.08A3.38,3.38,0,0,0,28,14.7a3.67,3.67,0,0,0,1.34-.26,3.71,3.71,0,0,0,1-.58v-1.5Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M32.93,12.61a2.53,2.53,0,0,0,.49,1.68,1.61,1.61,0,0,0,1.23.55,1.59,1.59,0,0,0,1.23-.56,2.57,2.57,0,0,0,.48-1.71,2.39,2.39,0,0,0-.49-1.63,1.55,1.55,0,0,0-1.22-.56,1.59,1.59,0,0,0-1.23.56,2.47,2.47,0,0,0-.49,1.67m-1,0a3,3,0,0,1,.9-2.4,2.66,2.66,0,0,1,1.83-.65,2.61,2.61,0,0,1,2,.79,3,3,0,0,1,.76,2.17A3.81,3.81,0,0,1,37,14.3a2.42,2.42,0,0,1-1,1,2.84,2.84,0,0,1-1.41.35,2.64,2.64,0,0,1-2-.78,3.15,3.15,0,0,1-.75-2.26"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M38.94,12.56a3,3,0,0,0,.29,1.52,1.41,1.41,0,0,0,1.26.76,1.43,1.43,0,0,0,1.12-.56,2.55,2.55,0,0,0,.47-1.68,2.59,2.59,0,0,0-.45-1.68,1.39,1.39,0,0,0-1.09-.55,1.44,1.44,0,0,0-1.12.57,2.43,2.43,0,0,0-.48,1.62m0,3H38v-8h1v2.88a2,2,0,0,1,1.59-.79,2.53,2.53,0,0,1,1,.22,2.18,2.18,0,0,1,.79.61,3,3,0,0,1,.49.94,4.17,4.17,0,0,1,.17,1.19,3.32,3.32,0,0,1-.74,2.32,2.33,2.33,0,0,1-1.78.81A1.86,1.86,0,0,1,39,14.79Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M43.81,9.69h1v5.83h-1Zm0-2.21h1V8.61h-1Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M46.55,12.05H49.8a2,2,0,0,0-.37-1.11,1.5,1.5,0,0,0-1.22-.57,1.55,1.55,0,0,0-1.15.46,1.78,1.78,0,0,0-.51,1.22m3.25,1.59,1,.13a2.4,2.4,0,0,1-.89,1.39,3,3,0,0,1-3.7-.29,3.09,3.09,0,0,1-.74-2.21,3.24,3.24,0,0,1,.75-2.29,2.56,2.56,0,0,1,2-.81,2.52,2.52,0,0,1,1.91.8,3.17,3.17,0,0,1,.74,2.24c0,.06,0,.14,0,.26H46.49A2.24,2.24,0,0,0,47,14.33a1.61,1.61,0,0,0,1.22.51,1.49,1.49,0,0,0,.92-.29,1.93,1.93,0,0,0,.62-.91"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M51.55,15.52V9.69h.89v.89a2.54,2.54,0,0,1,.62-.82,1.15,1.15,0,0,1,.64-.2,2,2,0,0,1,1,.32l-.34.92a1.37,1.37,0,0,0-.72-.22,1,1,0,0,0-.59.2,1.12,1.12,0,0,0-.37.54,4.15,4.15,0,0,0-.16,1.15v3Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M54.81,15.52V9.69h.89v.83a2.1,2.1,0,0,1,1.85-1,2.5,2.5,0,0,1,1,.19,1.54,1.54,0,0,1,.67.5,2.11,2.11,0,0,1,.3.72,5.34,5.34,0,0,1,.06,1v3.58h-1V12a2.78,2.78,0,0,0-.11-.9A.93.93,0,0,0,58,10.6a1.25,1.25,0,0,0-.68-.18,1.62,1.62,0,0,0-1.1.4,2,2,0,0,0-.45,1.52v3.18Z"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M61.22,12.61a2.48,2.48,0,0,0,.49,1.68,1.63,1.63,0,0,0,2.45,0,2.57,2.57,0,0,0,.48-1.71,2.39,2.39,0,0,0-.49-1.63,1.61,1.61,0,0,0-2.44,0,2.42,2.42,0,0,0-.49,1.67m-1,0a3,3,0,0,1,.9-2.4,2.66,2.66,0,0,1,1.83-.65,2.61,2.61,0,0,1,2,.79,3,3,0,0,1,.76,2.17,3.81,3.81,0,0,1-.34,1.78,2.42,2.42,0,0,1-1,1,2.84,2.84,0,0,1-1.41.35,2.65,2.65,0,0,1-2-.78,3.15,3.15,0,0,1-.75-2.26"
                fill="#6d6e70"
                fillRule="evenodd"
            />
            <path
                d="M70.66,12v2.15h1.51a8.4,8.4,0,0,0,1.11,0,1,1,0,0,0,.59-.32,1,1,0,0,0,.23-.69,1.09,1.09,0,0,0-.17-.61,1,1,0,0,0-.52-.37A5.29,5.29,0,0,0,72,12Zm0-3.19v1.86h1.07c.63,0,1,0,1.18,0a1,1,0,0,0,.65-.29.85.85,0,0,0,.24-.63.82.82,0,0,0-.21-.6A.93.93,0,0,0,73,8.84c-.15,0-.62,0-1.38,0ZM69,7.47h3.21a9.42,9.42,0,0,1,1.43.09,2.06,2.06,0,0,1,.84.33,1.92,1.92,0,0,1,.61.68,1.73,1.73,0,0,1,.25.93,1.94,1.94,0,0,1-1.13,1.75,2.06,2.06,0,0,1,1.13.73,1.88,1.88,0,0,1,.4,1.21,2.41,2.41,0,0,1-.26,1.07,2.18,2.18,0,0,1-.7.83,2.35,2.35,0,0,1-1.08.38c-.28,0-.93,0-2,0H69Z"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M77.87,12.61a1.91,1.91,0,0,0,.41,1.32,1.33,1.33,0,0,0,2,0,1.94,1.94,0,0,0,.41-1.33,1.91,1.91,0,0,0-.41-1.32,1.35,1.35,0,0,0-2,0,1.94,1.94,0,0,0-.41,1.33m-1.58-.09A3.15,3.15,0,0,1,76.66,11a2.66,2.66,0,0,1,1.08-1.1,3.2,3.2,0,0,1,1.55-.38,2.92,2.92,0,0,1,2.17.86,3,3,0,0,1,.85,2.17,3,3,0,0,1-.86,2.2,2.91,2.91,0,0,1-2.15.86,3.44,3.44,0,0,1-1.53-.36,2.58,2.58,0,0,1-1.11-1.06,3.6,3.6,0,0,1-.37-1.71"
                fill="#404041"
                fillRule="evenodd"
            />
            <rect
                x="83.02"
                y="7.48"
                width="1.54"
                height="8.05"
                fill="#404041"
            />
            <path
                d="M85.65,9.69h1.54v5.83H85.65Zm0-2.21h1.54V8.9H85.65Z"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M89.88,15.52,87.53,9.69h1.62l1.09,3,.33,1,.16-.5a4.66,4.66,0,0,1,.16-.49l1.11-3h1.58l-2.31,5.83Z"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M97.25,12.72a8.11,8.11,0,0,1-.94.24,2.78,2.78,0,0,0-.84.27.63.63,0,0,0-.3.54.76.76,0,0,0,.24.56.82.82,0,0,0,.62.23,1.26,1.26,0,0,0,.79-.27,1,1,0,0,0,.37-.51,3,3,0,0,0,.06-.75Zm-2.06-1.25-1.41-.25A2.23,2.23,0,0,1,94.6,10a3,3,0,0,1,1.71-.41,3.58,3.58,0,0,1,1.54.25,1.46,1.46,0,0,1,.71.62,3.27,3.27,0,0,1,.21,1.38l0,1.8a6.23,6.23,0,0,0,.07,1.13,3.52,3.52,0,0,0,.28.78H97.57c0-.1-.09-.26-.15-.45a1.47,1.47,0,0,0,0-.19,2.82,2.82,0,0,1-.84.58,2.43,2.43,0,0,1-1,.19,2,2,0,0,1-1.42-.49,1.76,1.76,0,0,1-.28-2.11,1.57,1.57,0,0,1,.66-.59,5.11,5.11,0,0,1,1.23-.36,8.63,8.63,0,0,0,1.5-.38v-.15a.76.76,0,0,0-.22-.63,1.29,1.29,0,0,0-.83-.2,1.08,1.08,0,0,0-.64.17,1,1,0,0,0-.37.56"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M101.27,15.52H99.72V9.69h1.44v.83a2.32,2.32,0,0,1,.66-.77,1.24,1.24,0,0,1,.66-.19,2,2,0,0,1,1,.29L103,11.2a1.35,1.35,0,0,0-.72-.26.82.82,0,0,0-.55.19,1.2,1.2,0,0,0-.36.64,9.79,9.79,0,0,0-.12,2Z"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M103.67,9.69h1.55v5.83h-1.55Zm0-2.21h1.55V8.9h-1.55Z"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M109.52,12.72a8.11,8.11,0,0,1-.94.24,2.68,2.68,0,0,0-.84.27.65.65,0,0,0-.31.54.77.77,0,0,0,.25.56.81.81,0,0,0,.61.23,1.29,1.29,0,0,0,.8-.27,1,1,0,0,0,.37-.51,3.64,3.64,0,0,0,.06-.75Zm-2.06-1.25-1.4-.25a2.12,2.12,0,0,1,.81-1.25,3,3,0,0,1,1.71-.41,3.5,3.5,0,0,1,1.53.25,1.49,1.49,0,0,1,.72.62,3.31,3.31,0,0,1,.2,1.38l0,1.8a6,6,0,0,0,.08,1.13,3.08,3.08,0,0,0,.28.78h-1.53c0-.1-.09-.26-.15-.45s0-.16,0-.19a2.74,2.74,0,0,1-.85.58,2.38,2.38,0,0,1-1,.19,2,2,0,0,1-1.42-.49,1.72,1.72,0,0,1-.28-2.11,1.57,1.57,0,0,1,.66-.59A5,5,0,0,1,108,12.1a8.89,8.89,0,0,0,1.5-.38v-.15a.8.8,0,0,0-.22-.63,1.31,1.31,0,0,0-.83-.2,1.12,1.12,0,0,0-.65.17,1,1,0,0,0-.36.56"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M117.36,15.52h-1.54v-3a4.81,4.81,0,0,0-.1-1.22.92.92,0,0,0-.33-.44.9.9,0,0,0-.53-.15,1.25,1.25,0,0,0-.72.22,1.15,1.15,0,0,0-.44.58,5.56,5.56,0,0,0-.11,1.34v2.64h-1.54V9.69h1.43v.86a2.36,2.36,0,0,1,2.85-.8,1.6,1.6,0,0,1,.64.46,1.7,1.7,0,0,1,.31.65,5.39,5.39,0,0,1,.08,1Z"
                fill="#404041"
                fillRule="evenodd"
            />
            <path
                d="M119.65,12.61a1.87,1.87,0,0,0,.42,1.32,1.32,1.32,0,0,0,2,0,1.89,1.89,0,0,0,.41-1.33,1.87,1.87,0,0,0-.41-1.32,1.34,1.34,0,0,0-2,0,1.9,1.9,0,0,0-.42,1.33m-1.58-.09a3.16,3.16,0,0,1,.38-1.48,2.58,2.58,0,0,1,1.07-1.1,3.24,3.24,0,0,1,1.56-.38,2.94,2.94,0,0,1,2.17.86,3,3,0,0,1,.84,2.17,3,3,0,0,1-.86,2.2,2.89,2.89,0,0,1-2.14.86,3.38,3.38,0,0,1-1.53-.36,2.48,2.48,0,0,1-1.11-1.06,3.6,3.6,0,0,1-.38-1.71"
                fill="#404041"
                fillRule="evenodd"
            />
            <rect
                x="129.27"
                y="7.15"
                width="1.02"
                height="17.51"
                fill="#404041"
            />
            <g clipPath="url(#clipPath)">
                <path
                    d="M0,24.76l1.34,5.92H2.82a18.32,18.32,0,0,1,5.25-5.46,19.14,19.14,0,0,1,7.15-2.48l-.93-7a15.38,15.38,0,0,0-7.9,2.66A24.28,24.28,0,0,0,0,24.76"
                    fill="#e22629"
                    fillRule="evenodd"
                />
                <path
                    d="M-1.54,11.34v6.78l1.43.07a20.88,20.88,0,0,1,5.44-5.36A15,15,0,0,1,12.93,11L12,5.38a10.15,10.15,0,0,0-8.57,1c-4.06,2.7-5,5-5,5"
                    fill="#ffe30e"
                    fillRule="evenodd"
                />
                <path
                    d="M0,17.91v6.88s3.38-4.45,6.61-6.49a19.24,19.24,0,0,1,7.15-2.47L12.8,11A11.63,11.63,0,0,0,5,13C.92,15.7,0,17.91,0,17.91"
                    fill="#2e5ea6"
                    fillRule="evenodd"
                />
            </g>
            <polygon
                points="9.87 14.54 9.13 14.48 8.85 15.15 8.68 14.44 7.95 14.37 8.58 14 8.41 13.28 8.97 13.77 9.6 13.39 9.31 14.06 9.87 14.54"
                fill="#fff"
                fillRule="evenodd"
            />
            <polygon
                points="7.21 15.79 6.48 15.73 6.2 16.41 6.03 15.69 5.3 15.63 5.93 15.25 5.76 14.54 6.32 15.02 6.94 14.64 6.66 15.31 7.21 15.79"
                fill="#fff"
                fillRule="evenodd"
            />
            <polygon
                points="4.83 17.57 4.1 17.51 3.81 18.18 3.64 17.47 2.91 17.4 3.54 17.03 3.38 16.31 3.93 16.79 4.56 16.41 4.27 17.09 4.83 17.57"
                fill="#fff"
                fillRule="evenodd"
            />
            <polygon
                points="12.64 14.05 11.91 13.99 11.63 14.66 11.46 13.95 10.73 13.89 11.36 13.51 11.19 12.79 11.74 13.28 12.37 12.89 12.09 13.57 12.64 14.05"
                fill="#fff"
                fillRule="evenodd"
            />
            <path
                d="M5,13c-4.06,2.7-5,4.92-5,4.92v6.85H0c.29-.37,3.51-4.51,6.59-6.46a19.06,19.06,0,0,1,7-2.45l-.68-4.2L12.8,11a7.23,7.23,0,0,0-1.1-.07A12.21,12.21,0,0,0,5,13"
                fill="none"
            />
            <g clipPath="url(#clipPath-2)">
                <path
                    d="M23.67,3.27l-.12-5.94-1.49,0A18.16,18.16,0,0,1,16.92,2.9,19.26,19.26,0,0,1,9.81,5.5l1,7a15.44,15.44,0,0,0,7.86-2.8,12.51,12.51,0,0,0,5-6.44"
                    fill="#ffe30e"
                    fillRule="evenodd"
                />
                <path
                    d="M26.77,16.6l-.12-6.77-1.32,0a22.74,22.74,0,0,1-5.46,5.39,15,15,0,0,1-7.57,2l1,5.61a10.15,10.15,0,0,0,8.55-1.16c4-2.77,4.89-5,4.89-5"
                    fill="#e22629"
                    fillRule="evenodd"
                />
                <path
                    d="M25.1,10,25,3.26l-1.32,0S21.72,7.7,18.53,9.79a19.34,19.34,0,0,1-7.12,2.6l1,4.86a11.6,11.6,0,0,0,7.78-2.17C24.23,12.3,25.1,10,25.1,10"
                    fill="#2e5ea6"
                    fillRule="evenodd"
                />
            </g>
            <polygon
                points="13.89 15.33 13.72 14.61 12.99 14.55 13.62 14.17 13.46 13.46 14.01 13.94 14.64 13.56 14.35 14.24 14.91 14.72 14.18 14.65 13.89 15.33"
                fill="#fff"
                fillRule="evenodd"
            />
            <path
                d="M18.53,9.79A18.19,18.19,0,0,1,13,12.08l.81,5.22a12.2,12.2,0,0,0,6.43-2.22c.39-.27.74-.53,1.06-.78V7.17a12,12,0,0,1-2.74,2.62"
                fill="none"
            />
            <polygon
                points="16.57 14.63 16.4 13.91 15.67 13.85 16.3 13.47 16.13 12.75 16.69 13.23 17.32 12.86 17.03 13.54 17.59 14.02 16.85 13.95 16.57 14.63"
                fill="#fff"
                fillRule="evenodd"
            />
            <polygon
                points="19.12 13.42 18.96 12.7 18.23 12.64 18.86 12.26 18.7 11.55 19.25 12.02 19.88 11.65 19.59 12.33 20.14 12.81 19.41 12.74 19.12 13.42"
                fill="#fff"
                fillRule="evenodd"
            />
            <polygon
                points="2.69 19.53 1.96 19.47 1.67 20.14 1.5 19.43 0.78 19.36 1.4 18.99 1.23 18.27 1.79 18.75 2.42 18.37 2.13 19.05 2.69 19.53"
                fill="#fff"
                fillRule="evenodd"
            />
            <path
                d="M135.65,17.16V12.38h.95l1.13,3.38c.1.32.18.55.23.71,0-.18.14-.43.25-.77l1.15-3.32h.85v4.78h-.61v-4l-1.39,4h-.57l-1.38-4.07v4.07Z"
                fill="#606060"
            />
            <path
                d="M141.09,13.05v-.67h.59v.67Zm0,4.11V13.7h.59v3.46Z"
                fill="#606060"
            />
            <path
                d="M142.5,17.16V13.7H143v.49a1.24,1.24,0,0,1,1.1-.57,1.4,1.4,0,0,1,.58.11.91.91,0,0,1,.39.29,1.24,1.24,0,0,1,.18.44,3.67,3.67,0,0,1,0,.57v2.13h-.58V15.05a1.76,1.76,0,0,0-.07-.54.65.65,0,0,0-.24-.28.8.8,0,0,0-.41-.1.94.94,0,0,0-.65.23,1.19,1.19,0,0,0-.27.91v1.89Z"
                fill="#606060"
            />
            <path
                d="M146.15,13.05v-.67h.59v.67Zm0,4.11V13.7h.59v3.46Z"
                fill="#606060"
            />
            <path
                d="M147.33,16.12l.58-.09a.83.83,0,0,0,.27.54,1,1,0,0,0,.62.18.92.92,0,0,0,.6-.16.5.5,0,0,0,.2-.39.36.36,0,0,0-.17-.31,2.6,2.6,0,0,0-.6-.2,5.85,5.85,0,0,1-.9-.28.83.83,0,0,1-.5-.8.87.87,0,0,1,.1-.42.92.92,0,0,1,.29-.33,1.06,1.06,0,0,1,.37-.17,1.7,1.7,0,0,1,.51-.07,2.27,2.27,0,0,1,.72.11,1,1,0,0,1,.45.32,1.27,1.27,0,0,1,.2.54l-.57.08a.63.63,0,0,0-.23-.42.82.82,0,0,0-.53-.15.92.92,0,0,0-.57.13.4.4,0,0,0-.18.32.37.37,0,0,0,.07.2.59.59,0,0,0,.23.16l.52.15a8.07,8.07,0,0,1,.87.27.83.83,0,0,1,.38.31.8.8,0,0,1,.14.5,1,1,0,0,1-.17.55,1.09,1.09,0,0,1-.49.4,1.73,1.73,0,0,1-.73.14,1.6,1.6,0,0,1-1-.28A1.24,1.24,0,0,1,147.33,16.12Z"
                fill="#606060"
            />
            <path
                d="M152.11,16.63l.09.52a2.69,2.69,0,0,1-.44.05,1,1,0,0,1-.5-.1.62.62,0,0,1-.25-.26,2.12,2.12,0,0,1-.07-.7v-2h-.43V13.7h.43v-.86l.58-.35V13.7h.59v.45h-.59v2a1.08,1.08,0,0,0,0,.32.29.29,0,0,0,.11.11.35.35,0,0,0,.2,0A1.24,1.24,0,0,0,152.11,16.63Z"
                fill="#606060"
            />
            <path
                d="M155,16l.6.08a1.43,1.43,0,0,1-.53.82,1.55,1.55,0,0,1-1,.29,1.59,1.59,0,0,1-1.2-.46,1.81,1.81,0,0,1-.45-1.31,1.91,1.91,0,0,1,.45-1.36,1.53,1.53,0,0,1,1.17-.48,1.49,1.49,0,0,1,1.14.47,1.88,1.88,0,0,1,.43,1.33v.16H153a1.32,1.32,0,0,0,.32.87,1,1,0,0,0,.73.3.88.88,0,0,0,.55-.17A1.11,1.11,0,0,0,155,16Zm-1.93-.95H155a1.11,1.11,0,0,0-.23-.65.88.88,0,0,0-.72-.34.93.93,0,0,0-.68.27A1,1,0,0,0,153.06,15.09Z"
                fill="#606060"
            />
            <path
                d="M156.26,17.16V13.7h.53v.52a1.42,1.42,0,0,1,.37-.49.7.7,0,0,1,.38-.11,1.14,1.14,0,0,1,.6.19l-.2.54a.92.92,0,0,0-.43-.13.55.55,0,0,0-.35.12.68.68,0,0,0-.22.32,2.48,2.48,0,0,0-.09.68v1.82Z"
                fill="#606060"
            />
            <path
                d="M158.43,13.05v-.67H159v.67Zm0,4.11V13.7H159v3.46Z"
                fill="#606060"
            />
            <path
                d="M159.62,15.43a1.81,1.81,0,0,1,.53-1.43,1.64,1.64,0,0,1,1.09-.38,1.55,1.55,0,0,1,1.17.46,1.82,1.82,0,0,1,.45,1.3,2.23,2.23,0,0,1-.2,1,1.43,1.43,0,0,1-.58.59,1.74,1.74,0,0,1-.84.21,1.55,1.55,0,0,1-1.17-.46A1.86,1.86,0,0,1,159.62,15.43Zm.6,0a1.48,1.48,0,0,0,.29,1,1,1,0,0,0,.73.33.93.93,0,0,0,.73-.33,1.52,1.52,0,0,0,.29-1,1.47,1.47,0,0,0-.29-1,1,1,0,0,0-1.46,0A1.49,1.49,0,0,0,160.22,15.43Z"
                fill="#606060"
            />
            <path
                d="M167.52,17.16v-.44a1.09,1.09,0,0,1-1,.51,1.35,1.35,0,0,1-.76-.22,1.61,1.61,0,0,1-.54-.64,2.16,2.16,0,0,1-.19-.94,2.54,2.54,0,0,1,.17-.94,1.41,1.41,0,0,1,.52-.65,1.38,1.38,0,0,1,.77-.22,1.13,1.13,0,0,1,.56.13,1.32,1.32,0,0,1,.4.34V12.38h.58v4.78Zm-1.86-1.73a1.54,1.54,0,0,0,.28,1,.85.85,0,0,0,.66.33.83.83,0,0,0,.66-.31,1.45,1.45,0,0,0,.27-1,1.62,1.62,0,0,0-.28-1,.82.82,0,0,0-.67-.34.84.84,0,0,0-.66.32A1.61,1.61,0,0,0,165.66,15.43Z"
                fill="#606060"
            />
            <path
                d="M171.29,16l.6.08a1.43,1.43,0,0,1-.53.82,1.81,1.81,0,0,1-2.19-.17,1.81,1.81,0,0,1-.45-1.31,1.91,1.91,0,0,1,.45-1.36,1.53,1.53,0,0,1,1.17-.48,1.45,1.45,0,0,1,1.13.47,1.89,1.89,0,0,1,.44,1.33v.16h-2.58a1.32,1.32,0,0,0,.32.87,1,1,0,0,0,.72.3.84.84,0,0,0,.55-.17A1.06,1.06,0,0,0,171.29,16Zm-1.93-.95h1.93a1.11,1.11,0,0,0-.22-.65.9.9,0,0,0-.73-.34.94.94,0,0,0-.68.27A1.08,1.08,0,0,0,169.36,15.09Z"
                fill="#606060"
            />
            <path d="M172.55,17.16V12.38h.58v4.78Z" fill="#606060" />
            <path
                d="M175.84,17.16V12.38h1.8a4.57,4.57,0,0,1,.73,0,1.49,1.49,0,0,1,.59.23,1.16,1.16,0,0,1,.38.46,1.41,1.41,0,0,1,.15.65,1.45,1.45,0,0,1-.39,1,1.87,1.87,0,0,1-1.4.42h-1.23v1.95Zm.63-2.51h1.24a1.31,1.31,0,0,0,.87-.23.8.8,0,0,0,.25-.64.85.85,0,0,0-.15-.51.69.69,0,0,0-.4-.29,3.36,3.36,0,0,0-.59,0h-1.22Z"
                fill="#606060"
            />
            <path
                d="M179.93,15.43a1.78,1.78,0,0,1,.54-1.43,1.62,1.62,0,0,1,1.08-.38,1.55,1.55,0,0,1,1.17.46,1.82,1.82,0,0,1,.45,1.3,2.23,2.23,0,0,1-.2,1,1.43,1.43,0,0,1-.58.59,1.72,1.72,0,0,1-.84.21,1.55,1.55,0,0,1-1.17-.46A1.86,1.86,0,0,1,179.93,15.43Zm.6,0a1.48,1.48,0,0,0,.29,1,1,1,0,0,0,.73.33.93.93,0,0,0,.73-.33,1.52,1.52,0,0,0,.29-1,1.47,1.47,0,0,0-.29-1,1,1,0,0,0-1.46,0A1.49,1.49,0,0,0,180.53,15.43Z"
                fill="#606060"
            />
            <path
                d="M186,17.16v-.44a1.08,1.08,0,0,1-1,.51,1.37,1.37,0,0,1-.76-.22,1.61,1.61,0,0,1-.54-.64,2.32,2.32,0,0,1-.19-.94,2.35,2.35,0,0,1,.18-.94,1.33,1.33,0,0,1,.51-.65,1.39,1.39,0,0,1,.78-.22,1.09,1.09,0,0,1,.55.13,1.12,1.12,0,0,1,.4.34V12.38h.58v4.78Zm-1.85-1.73a1.48,1.48,0,0,0,.28,1,.83.83,0,0,0,1.31,0,1.45,1.45,0,0,0,.27-1,1.62,1.62,0,0,0-.27-1,.84.84,0,0,0-.68-.34.81.81,0,0,0-.65.32A1.55,1.55,0,0,0,184.19,15.43Z"
                fill="#606060"
            />
            <path
                d="M189.81,16l.61.08a1.49,1.49,0,0,1-.54.82,1.53,1.53,0,0,1-1,.29,1.59,1.59,0,0,1-1.2-.46,1.81,1.81,0,0,1-.45-1.31,1.91,1.91,0,0,1,.45-1.36,1.53,1.53,0,0,1,1.17-.48,1.49,1.49,0,0,1,1.14.47,1.88,1.88,0,0,1,.43,1.33v.16h-2.58a1.32,1.32,0,0,0,.32.87,1,1,0,0,0,.73.3.86.86,0,0,0,.55-.17A1.11,1.11,0,0,0,189.81,16Zm-1.93-.95h1.94a1.11,1.11,0,0,0-.23-.65.86.86,0,0,0-.72-.34.93.93,0,0,0-.68.27A1,1,0,0,0,187.88,15.09Z"
                fill="#606060"
            />
            <path
                d="M191.08,17.16V13.7h.53v.52a1.42,1.42,0,0,1,.37-.49.7.7,0,0,1,.38-.11,1.14,1.14,0,0,1,.6.19l-.2.54a.92.92,0,0,0-.43-.13.55.55,0,0,0-.35.12.68.68,0,0,0-.22.32,2.48,2.48,0,0,0-.09.68v1.82Z"
                fill="#606060"
            />
            <path
                d="M195.1,17.16V12.38h1.81a4.43,4.43,0,0,1,.72,0,1.43,1.43,0,0,1,.59.23,1.09,1.09,0,0,1,.38.46,1.41,1.41,0,0,1,.15.65,1.45,1.45,0,0,1-.39,1,1.86,1.86,0,0,1-1.4.42h-1.22v1.95Zm.64-2.51H197a1.31,1.31,0,0,0,.87-.23.81.81,0,0,0,.26-.64.85.85,0,0,0-.15-.51.72.72,0,0,0-.4-.29,3.54,3.54,0,0,0-.59,0h-1.22Z"
                fill="#606060"
            />
            <path
                d="M199.2,15.43a1.77,1.77,0,0,1,.53-1.43,1.64,1.64,0,0,1,1.09-.38,1.58,1.58,0,0,1,1.17.46,1.82,1.82,0,0,1,.45,1.3,2.36,2.36,0,0,1-.2,1,1.46,1.46,0,0,1-.59.59,1.68,1.68,0,0,1-.83.21,1.56,1.56,0,0,1-1.18-.46A1.85,1.85,0,0,1,199.2,15.43Zm.6,0a1.43,1.43,0,0,0,.29,1,.93.93,0,0,0,.73.33,1,1,0,0,0,.73-.33,1.52,1.52,0,0,0,.29-1,1.42,1.42,0,0,0-.3-1,.91.91,0,0,0-.72-.33.93.93,0,0,0-.73.33A1.44,1.44,0,0,0,199.8,15.43Z"
                fill="#606060"
            />
            <path
                d="M203.06,18.48V13.7h.53v.45a1.34,1.34,0,0,1,.43-.4,1.16,1.16,0,0,1,.58-.13,1.33,1.33,0,0,1,.78.23,1.37,1.37,0,0,1,.51.64,2.27,2.27,0,0,1,.17.91,2.32,2.32,0,0,1-.19,1,1.41,1.41,0,0,1-.55.65,1.43,1.43,0,0,1-.77.22,1.11,1.11,0,0,1-.52-.12,1.34,1.34,0,0,1-.39-.31v1.68Zm.53-3a1.49,1.49,0,0,0,.27,1,.81.81,0,0,0,.65.32.83.83,0,0,0,.67-.33,1.53,1.53,0,0,0,.28-1,1.54,1.54,0,0,0-.27-1,.8.8,0,0,0-1.31,0A1.57,1.57,0,0,0,203.59,15.45Z"
                fill="#606060"
            />
            <path
                d="M209,17.16v-.51a1.26,1.26,0,0,1-1.1.58,1.38,1.38,0,0,1-.57-.11.94.94,0,0,1-.39-.3,1,1,0,0,1-.18-.43,2.51,2.51,0,0,1,0-.55V13.7h.59v1.91a4,4,0,0,0,0,.62.64.64,0,0,0,.24.37.72.72,0,0,0,.44.13,1,1,0,0,0,.5-.14.74.74,0,0,0,.32-.36,1.74,1.74,0,0,0,.1-.68V13.7h.59v3.46Z"
                fill="#606060"
            />
            <path d="M210.34,17.16V12.38h.58v4.78Z" fill="#606060" />
            <path
                d="M214,16.73a2.26,2.26,0,0,1-.62.39,1.74,1.74,0,0,1-.65.11,1.3,1.3,0,0,1-.88-.27,1,1,0,0,1-.3-.72.9.9,0,0,1,.11-.46,1,1,0,0,1,.31-.34,1.35,1.35,0,0,1,.42-.19,4.41,4.41,0,0,1,.52-.09,4.75,4.75,0,0,0,1-.2,1.14,1.14,0,0,0,0-.15.66.66,0,0,0-.17-.51,1,1,0,0,0-.67-.2,1,1,0,0,0-.61.15.86.86,0,0,0-.29.51l-.57-.08a1.39,1.39,0,0,1,.26-.59,1.08,1.08,0,0,1,.51-.35,2.39,2.39,0,0,1,.79-.12,2.12,2.12,0,0,1,.72.1,1.07,1.07,0,0,1,.41.26,1.13,1.13,0,0,1,.18.4,3.22,3.22,0,0,1,0,.54v.79a7.14,7.14,0,0,0,0,1,1.3,1.3,0,0,0,.14.42h-.61A1.43,1.43,0,0,1,214,16.73ZM214,15.42a4.06,4.06,0,0,1-.95.22,2,2,0,0,0-.51.12.57.57,0,0,0-.24.19.54.54,0,0,0-.08.28.51.51,0,0,0,.18.39.79.79,0,0,0,.52.15,1.16,1.16,0,0,0,.6-.14.91.91,0,0,0,.39-.41,1.49,1.49,0,0,0,.09-.59Z"
                fill="#606060"
            />
            <path
                d="M215.4,17.16V13.7h.53v.52a1.42,1.42,0,0,1,.38-.49.7.7,0,0,1,.37-.11,1.09,1.09,0,0,1,.6.19l-.2.54a.86.86,0,0,0-.43-.13.52.52,0,0,0-.34.12.62.62,0,0,0-.22.32,2.15,2.15,0,0,0-.1.68v1.82Z"
                fill="#606060"
            />
            <path
                d="M135.59,25.68V20.89h.54v.45a1.29,1.29,0,0,1,.42-.39,1.16,1.16,0,0,1,.58-.13,1.35,1.35,0,0,1,.78.22,1.38,1.38,0,0,1,.51.65,2.24,2.24,0,0,1,.18.91,2.49,2.49,0,0,1-.19,1,1.49,1.49,0,0,1-.56.65,1.42,1.42,0,0,1-.76.22,1.15,1.15,0,0,1-.53-.12,1.29,1.29,0,0,1-.38-.31v1.68Zm.53-3a1.5,1.5,0,0,0,.27,1,.84.84,0,0,0,.66.32.86.86,0,0,0,.67-.33,1.59,1.59,0,0,0,.28-1,1.45,1.45,0,0,0-.28-1,.81.81,0,0,0-.65-.33.82.82,0,0,0-.66.35A1.56,1.56,0,0,0,136.12,22.64Z"
                fill="#606060"
            />
            <path
                d="M141.5,23.93a2.46,2.46,0,0,1-.63.39,1.74,1.74,0,0,1-.65.11,1.3,1.3,0,0,1-.88-.27,1,1,0,0,1-.3-.72.93.93,0,0,1,.42-.8,1.35,1.35,0,0,1,.42-.19,4.41,4.41,0,0,1,.52-.09,5.76,5.76,0,0,0,1-.2V22a.66.66,0,0,0-.17-.51,1,1,0,0,0-.66-.2,1,1,0,0,0-.62.15.88.88,0,0,0-.29.51l-.57-.08a1.39,1.39,0,0,1,.26-.59,1.11,1.11,0,0,1,.52-.35,2.28,2.28,0,0,1,.78-.12,2,2,0,0,1,.72.1,1,1,0,0,1,.41.26.87.87,0,0,1,.18.4,3.22,3.22,0,0,1,0,.54v.79a7.14,7.14,0,0,0,0,1,1.18,1.18,0,0,0,.15.42h-.62A1.18,1.18,0,0,1,141.5,23.93Zm-.05-1.31a4.3,4.3,0,0,1-1,.22A2.12,2.12,0,0,0,140,23a.43.43,0,0,0-.23.19.45.45,0,0,0-.09.28.51.51,0,0,0,.18.39.79.79,0,0,0,.52.15,1.27,1.27,0,0,0,.6-.14.91.91,0,0,0,.39-.41,1.31,1.31,0,0,0,.1-.59Z"
                fill="#606060"
            />
            <path
                d="M142.88,24.36V20.89h.52v.53a1.42,1.42,0,0,1,.38-.49.7.7,0,0,1,.37-.11,1.14,1.14,0,0,1,.61.19l-.21.54a.77.77,0,0,0-.43-.13.54.54,0,0,0-.34.12.62.62,0,0,0-.22.32,2.15,2.15,0,0,0-.1.68v1.82Z"
                fill="#606060"
            />
            <path
                d="M147.3,23.93a2.46,2.46,0,0,1-.63.39,1.74,1.74,0,0,1-.65.11,1.26,1.26,0,0,1-.87-.27.92.92,0,0,1-.31-.72.93.93,0,0,1,.42-.8,1.35,1.35,0,0,1,.42-.19l.52-.09a5.58,5.58,0,0,0,1.05-.2V22a.63.63,0,0,0-.17-.51,1,1,0,0,0-.66-.2,1,1,0,0,0-.62.15.88.88,0,0,0-.29.51l-.57-.08a1.39,1.39,0,0,1,.26-.59,1.11,1.11,0,0,1,.52-.35,2.28,2.28,0,0,1,.78-.12,2,2,0,0,1,.72.1,1,1,0,0,1,.41.26.87.87,0,0,1,.18.4,3.22,3.22,0,0,1,0,.54v.79a7.14,7.14,0,0,0,0,1,1.18,1.18,0,0,0,.15.42h-.62A1.18,1.18,0,0,1,147.3,23.93Zm-.05-1.31a4.16,4.16,0,0,1-1,.22,2.12,2.12,0,0,0-.51.12.43.43,0,0,0-.23.19.44.44,0,0,0-.08.28.5.5,0,0,0,.17.39.79.79,0,0,0,.52.15,1.27,1.27,0,0,0,.6-.14.91.91,0,0,0,.39-.41,1.31,1.31,0,0,0,.1-.59Z"
                fill="#606060"
            />
            <path
                d="M152.84,23.24l.61.08a1.46,1.46,0,0,1-1.53,1.11,1.59,1.59,0,0,1-1.2-.46,1.84,1.84,0,0,1-.45-1.32,1.94,1.94,0,0,1,.45-1.35,1.53,1.53,0,0,1,1.17-.48,1.49,1.49,0,0,1,1.14.47,1.89,1.89,0,0,1,.44,1.33s0,.09,0,.15h-2.58a1.33,1.33,0,0,0,.32.88,1,1,0,0,0,.73.3.86.86,0,0,0,.55-.17A1.11,1.11,0,0,0,152.84,23.24Zm-1.93-.95h1.94a1.11,1.11,0,0,0-.23-.65.86.86,0,0,0-.72-.34.93.93,0,0,0-.68.27A1,1,0,0,0,150.91,22.29Z"
                fill="#606060"
            />
            <path d="M154.1,24.36V19.58h.59v4.78Z" fill="#606060" />
            <path
                d="M158.44,24.36v-4H157v-.81h3.8v.81h-1.41v4Z"
                fill="#404041"
            />
            <path
                d="M161.88,24.36H161V20.89h.85v.5a1.36,1.36,0,0,1,.4-.46.64.64,0,0,1,.39-.11,1.09,1.09,0,0,1,.6.17l-.28.8a.8.8,0,0,0-.43-.15.52.52,0,0,0-.33.1.87.87,0,0,0-.21.39,5.64,5.64,0,0,0-.07,1.16Z"
                fill="#404041"
            />
            <path
                d="M164.22,22l-.84-.15a1.33,1.33,0,0,1,.49-.74,1.73,1.73,0,0,1,1-.24,2.19,2.19,0,0,1,.92.14.91.91,0,0,1,.42.37,1.92,1.92,0,0,1,.12.82v1.07a3.69,3.69,0,0,0,0,.67,2.07,2.07,0,0,0,.17.47h-.91l-.08-.27a.53.53,0,0,0,0-.11,1.64,1.64,0,0,1-.5.34,1.38,1.38,0,0,1-.57.11,1.13,1.13,0,0,1-.84-.29.93.93,0,0,1-.31-.73,1,1,0,0,1,.14-.52.83.83,0,0,1,.39-.35,2.66,2.66,0,0,1,.73-.22,4.86,4.86,0,0,0,.89-.22V22a.46.46,0,0,0-.13-.38.75.75,0,0,0-.49-.11.69.69,0,0,0-.38.09A.67.67,0,0,0,164.22,22Zm1.22.74a4.44,4.44,0,0,1-.56.14,2.06,2.06,0,0,0-.5.16.42.42,0,0,0-.17.33.45.45,0,0,0,.14.33.51.51,0,0,0,.36.14.86.86,0,0,0,.48-.16.58.58,0,0,0,.21-.31,1.46,1.46,0,0,0,0-.44Z"
                fill="#404041"
            />
            <path
                d="M170.32,24.36h-.91V22.59a2.74,2.74,0,0,0-.06-.73.48.48,0,0,0-.19-.25.55.55,0,0,0-.32-.09.68.68,0,0,0-.42.13.59.59,0,0,0-.26.34,2.91,2.91,0,0,0-.07.8v1.57h-.92V20.89H168v.51a1.38,1.38,0,0,1,1.14-.58,1.3,1.3,0,0,1,.56.11.89.89,0,0,1,.37.27,1,1,0,0,1,.18.39,2.47,2.47,0,0,1,0,.61Z"
                fill="#404041"
            />
            <path
                d="M170.86,23.37l.92-.14a.65.65,0,0,0,.24.4.76.76,0,0,0,.5.14.9.9,0,0,0,.54-.13.29.29,0,0,0,.12-.24.24.24,0,0,0-.07-.18.74.74,0,0,0-.3-.12,5.14,5.14,0,0,1-1.41-.44.91.91,0,0,1-.41-.78,1,1,0,0,1,.36-.76,1.66,1.66,0,0,1,1.1-.3,1.86,1.86,0,0,1,1.05.23,1.19,1.19,0,0,1,.48.68l-.86.16a.58.58,0,0,0-.21-.31.79.79,0,0,0-.44-.11.93.93,0,0,0-.52.11.2.2,0,0,0,0,.35,4.11,4.11,0,0,0,.86.26,2.51,2.51,0,0,1,1,.4.86.86,0,0,1,.29.68,1,1,0,0,1-.4.82,1.77,1.77,0,0,1-1.18.34,2,2,0,0,1-1.12-.28A1.36,1.36,0,0,1,170.86,23.37Z"
                fill="#404041"
            />
            <path
                d="M174.81,20.89h.85v.51a1.3,1.3,0,0,1,.45-.42,1.23,1.23,0,0,1,.63-.16,1.3,1.3,0,0,1,1,.47,1.9,1.9,0,0,1,.42,1.32,2,2,0,0,1-.42,1.34,1.33,1.33,0,0,1-1,.48,1.13,1.13,0,0,1-.52-.11,2,2,0,0,1-.49-.39v1.74h-.91Zm.9,1.68a1.36,1.36,0,0,0,.23.86.75.75,0,0,0,.57.28.67.67,0,0,0,.53-.26,1.33,1.33,0,0,0,.21-.84,1.24,1.24,0,0,0-.22-.81.67.67,0,0,0-.54-.26.73.73,0,0,0-.56.26A1.2,1.2,0,0,0,175.71,22.57Z"
                fill="#404041"
            />
            <path
                d="M178.63,22.58a1.9,1.9,0,0,1,.23-.89,1.58,1.58,0,0,1,.63-.65,1.9,1.9,0,0,1,.92-.22,1.72,1.72,0,0,1,1.29.51,1.74,1.74,0,0,1,.5,1.29,1.82,1.82,0,0,1-.5,1.3,1.74,1.74,0,0,1-1.28.51,2.06,2.06,0,0,1-.91-.21,1.42,1.42,0,0,1-.65-.63A2.12,2.12,0,0,1,178.63,22.58Zm.94.05a1.14,1.14,0,0,0,.24.78.78.78,0,0,0,.61.28.75.75,0,0,0,.6-.28,1.4,1.4,0,0,0,0-1.57.75.75,0,0,0-.6-.28.78.78,0,0,0-.61.28A1.15,1.15,0,0,0,179.57,22.63Z"
                fill="#404041"
            />
            <path
                d="M183.73,24.36h-.92V20.89h.85v.5a1.36,1.36,0,0,1,.4-.46.64.64,0,0,1,.39-.11,1.15,1.15,0,0,1,.61.17l-.29.8a.8.8,0,0,0-.43-.15.47.47,0,0,0-.32.1.71.71,0,0,0-.21.39,4.79,4.79,0,0,0-.08,1.16Z"
                fill="#404041"
            />
            <path
                d="M187,20.89v.73h-.63V23a3.51,3.51,0,0,0,0,.49.25.25,0,0,0,.08.12.29.29,0,0,0,.16,0,1.12,1.12,0,0,0,.37-.08l.08.71a1.9,1.9,0,0,1-.73.13,1,1,0,0,1-.44-.08.7.7,0,0,1-.3-.21.85.85,0,0,1-.12-.36,4.39,4.39,0,0,1,0-.65V21.62H185v-.73h.42v-.68l.91-.54v1.22Z"
                fill="#404041"
            />
            <path
                d="M189.54,23.25l.92.16a1.52,1.52,0,0,1-.56.76,1.59,1.59,0,0,1-1,.26,1.54,1.54,0,0,1-1.34-.59,2,2,0,0,1-.34-1.19,1.93,1.93,0,0,1,.45-1.35,1.5,1.5,0,0,1,1.14-.48,1.55,1.55,0,0,1,1.22.51,2.21,2.21,0,0,1,.42,1.56h-2.29a.94.94,0,0,0,.22.63.7.7,0,0,0,.53.23.56.56,0,0,0,.36-.12A.7.7,0,0,0,189.54,23.25Zm.05-.92a.86.86,0,0,0-.2-.61.66.66,0,0,0-.48-.2.61.61,0,0,0-.49.22.79.79,0,0,0-.19.59Z"
                fill="#404041"
            />
        </svg>
    )
}
